*::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
  transition-property: background-color outline;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

*::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  transition-property: background-color outline;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

html *::-webkit-scrollbar {
  background-color: #f5f5f5;
}

html.dark *::-webkit-scrollbar {
  background-color: #171717;
}

html *::-webkit-scrollbar-thumb {
  background-color: #525252;
  outline: 1px #525252;
}

html.dark *::-webkit-scrollbar-thumb {
  background-color: #a3a3a3;
  outline: 1px #a3a3a3;
}
